<template>
  <st-side-page class="patient-page">
    <portal to="page-name">Cadastro de paciente</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <template slot="side">
        <div class="patient-image" :style="bgImage"
             :class="{'show-options': showImageOptions}">
          <div class="patient-image-options">
            <template v-if="bgImage">
              <button class="btn btn-icon btn-error tooltip" data-tooltip="Remover foto"
                      @click="removeImage()">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar foto"
                      @click="image.showOptions = true">
                <fa-icon :icon="['fal', 'upload']"/>
                <input type="file" name="image" accept="image/*"
                       @change="loadImage">
              </button>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Tirar foto"
                      @click="openWebCam" :disabled="!hasWebcam">
                <fa-icon :icon="['fal', 'camera']"/>
              </button>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-12 form-group mt-2">
            <button class="btn btn-block btn-primary" @click="savePatient"
                    :disabled="saving" :class="{loading: saving}">
              Salvar
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-block btn-gray"
                    :class="{loading: printing}"
                    :disabled="printing || !form.id"
                    @click="print">
              Imprimir
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-block" @click="$router.back()">
              Voltar
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-neutral btn-block" @click="modalAlertShow = true">
              Avisos
            </button>
          </div>
        </div>
        <ul class="menu">
          <li class="menu-item" v-for="(menu, i) in menus" :key="i">
            <router-link :to="menu.path">{{ menu.label }}</router-link>
            <fa-icon :icon="['fal', 'chevron-right']"/>
          </li>
        </ul>
      </template>
      <div class="card">
        <div class="card-header">
          <div class="form-group float-right status-toggle">
            <label class="form-switch">
              <input type="checkbox" v-model="form.patient.active">
              <i class="form-icon"/> Registro ativo
            </label>
          </div>
          <div class="card-title">
            Cadastro de paciente
            <span v-if="form.name"> - </span>
            <small class="text-info"> {{ form.name }}</small>
          </div>
        </div>
        <div class="card-body">
          <st-tabs ref="patient-tabs">
            <st-tab id="patient" name="Dados principais">
              <div class="columns">
                <!-- Primary Identity -->
                <div class="column col-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.identity.value.$error}">
                  <label for="identity" class="form-label">Documento principal</label>
                  <st-input-identity
                    v-model="form.identity"
                    :types="primaryIdentityTypes"
                    @blur="$v.form.identity.value.$touch()"
                    @change="loadEntityPatient"
                    :disabled="isIdentitySaved"
                  ></st-input-identity>
                  <template v-if="$v.form.identity.value.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.identity.value.cpf">CPF inválido</div>
                  </template>
                </div>
                <!-- Name -->
                <div class="column col-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.name.$error}">
                  <label for="company-name" class="form-label">
                    Nome <small class="text-info">[Igual ao documento]</small>
                  </label>
                  <input type="text" id="company-name" name="company-name"
                         v-model="form.name" class="form-input"
                         @blur="$v.form.name.$touch()" @change="loadEntityByNameAndDob"
                         placeholder="Nome" autocomplete="nope">
                  <template v-if="$v.form.name.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.name.required">Campo obrigatório</div>
                  </template>
                </div>
                <!-- Birthdate -->
                <div class="column col-2 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.birthDate.$error}">
                  <label for="person-birth-date" class="form-label">Nascimento</label>
                  <input type="text" id="person-birth-date" name="person-birth-date"
                         v-model="form.birthDate" class="form-input"
                         @change="loadEntityByNameAndDob"
                         @blur="$v.form.birthDate.$touch()" key="person-birth-date"
                         placeholder="00/00/0000" v-mask-date.br>
                  <template v-if="$v.form.birthDate.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.birthDate.required">Campo obrigatório</div>
                    <div class="form-input-hint"
                         v-else-if="!$v.form.birthDate.date">Data inválida</div>
                  </template>
                </div>
                <!-- Biological Gender -->
                <div class="column col-2 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.gender.$error}">
                  <label for="person-gender" class="form-label">Sexo biológico</label>
                  <select id="person-gender" name="person-gender"
                          v-model="form.gender" class="form-select"
                          @change="$v.form.gender.$touch()">
                    <option value="">[Selecione]</option>
                    <option value="female">Feminino</option>
                    <option value="male">Masculino</option>
                  </select>
                  <template v-if="$v.form.gender.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.gender.required">Campo obrigatório</div>
                  </template>
                </div>
                <!-- Cellphone -->
                <div class="column col-3 col-lg-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.cellphone.$error}">
                  <label for="cellphone" class="form-label">Celular</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="cellphone"
                      name="cellphone"
                      v-model="form.cellphone"
                      class="form-input"
                      autocomplete="nope"
                      @blur="$v.form.cellphone.$touch()"
                      placeholder="(00) 0 0000-0000"
                      v-mask-phone.br>
                    <a
                      :href="whatsappLink"
                      class="btn input-group-btn btn-neutral btn-icon tooltip"
                      data-tooltip="Enviar mensagem"
                      target="_blank"
                      v-if="whatsappLink"
                    ><fa-icon :icon="['fab', 'whatsapp']" class="text-info"></fa-icon></a>
                  </div>
                  <template v-if="$v.form.cellphone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.cellphone.phone">Telefone inválido</div>
                  </template>
                </div>
                <!-- Phone -->
                <div class="column col-3 col-lg-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.phone.$error}">
                  <label for="phone" class="form-label">Telefone</label>
                  <input type="text" id="phone" name="phone" v-model="form.phone"
                         class="form-input" @blur="$v.form.phone.$touch()" maxlength="14"
                         placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
                  <template v-if="$v.form.phone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.phone.phone">Telefone inválido</div>
                  </template>
                </div>
                <!-- Email -->
                <div class="column col-4 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.form.email.$error}">
                  <label for="email" class="form-label">Email</label>
                  <input type="text" id="email" name="email" v-model="form.email"
                         class="form-input" @blur="$v.form.email.$touch()"
                         placeholder="nome@email.com" autocomplete="nope">
                  <template v-if="$v.form.email.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.email.email">Email inválido</div>
                  </template>
                </div>
                <!-- Civil Status -->
                <div class="column col-2 col-lg-4 col-md-6 col-sm-12 form-group">
                  <label class="form-label">Estado civil</label>
                  <select id="civil-status" class="form-select"
                          v-model="form.civilStatus">
                    <option value="">[Selecione]</option>
                    <option v-for="(text, value) in civilStatuses"
                            :value="value" :key="value">{{ text }}</option>
                  </select>
                </div>
                <!-- Social Name -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="social-name" class="form-label">Nome social</label>
                  <input type="text" id="social-name" v-model="form.socialName"
                         class="form-input" placeholder="" autocomplete="nope">
                </div>
                <!-- Mother Name -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="mother-name" class="form-label">Nome da mãe</label>
                  <input type="text" id="mother-name" v-model="form.patient.motherName"
                         class="form-input" placeholder="" autocomplete="nope">
                </div>
                <!-- Father Name -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="father-name" class="form-label">Nome do pai</label>
                  <input type="text" id="father-name" v-model="form.patient.fatherName"
                         class="form-input" placeholder="" autocomplete="nope">
                </div>
                <!-- Secondary Identity -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="company-cpf" class="form-label">Documento secundário</label>
                  <st-input-identity
                    v-model="form.secondaryIdentity"
                    :types="secondaryIdentityTypes"
                  ></st-input-identity>
                </div>
                <!-- Occupation -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label class="form-label">Profissão</label>
                  <dx-autocomplete
                    v-model="profession"
                    label="name"
                    :source="findCbo"
                    @select="setCbo"
                    :debounce="800"
                    :readonly="!!profession"
                    placeholder="Pesquisar profissão">
                    <button slot="action" @click="unsetCbo"
                            class="btn btn-action input-group-btn btn-icon"
                            :class="profession ? 'btn-gray' : 'btn-neutral'"
                            tabindex="-1">
                      <fa-icon :icon="['fal', profession ? 'times' : 'search']" />
                    </button>
                    <template v-slot="{ item }">
                      <strong class="text-primary">{{ item.name }}</strong>
                    </template>
                  </dx-autocomplete>
                </div>
                <!-- CNS -->
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="phone" class="form-label">CNS</label>
                  <input type="text" id="cns" name="cns" v-model="form.patient.cns"
                         class="form-input" placeholder="Cartão nacional de saúde"
                         v-mask-number autocomplete="nope">
                </div>
              </div>

              <div class="text-gray">Dados do convênio</div>
              <div class="divider"></div>
              <div class="columns">
                <div class="column col-5 col-md-12 col-sm-12 form-group"
                     :class="{'has-error': $v.form.patient.insurance.planId.$error}">
                  <label for="insurance" class="form-label">Convênio</label>
                  <select id="insurance" class="form-select"
                          v-model="form.patient.insurance.planId"
                          @blur="$v.form.patient.insurance.planId.$touch()">
                    <option value="">[Selecione]</option>
                    <option v-for="(item, i) in insurances"
                            :value="item.plan.id" :key="i">{{ item.customName }}</option>
                  </select>
                  <template v-if="$v.form.patient.insurance.planId.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.patient.insurance.planId.required">
                      Campo obrigatório
                    </div>
                  </template>
                </div>
                <div class="column col-4 col-md-12 col-sm-12 form-group">
                  <label for="insurance-record" class="form-label">Carteirinha</label>
                  <input type="text" id="insurance-record" name="insurance-record"
                         v-model="form.patient.insurance.record" class="form-input"
                         placeholder="000000" autocomplete="nope" v-mask-number>
                </div>
                <div class="column col-3 col-md-12 col-sm-12 form-group">
                  <label class="form-label">Validade</label>
                  <dx-input-date type="text" id="insurance-validity" name="insurance-validity"
                         v-model="form.patient.insurance.validity" class="form-input"
                         placeholder="00/00/0000" autocomplete="nope" />
                </div>
              </div>

              <div class="text-gray">Dados do endereço</div>
              <div class="divider"></div>
              <div class="columns">
                <div class="column col-3 col-md-6 col-sm-12 form-group"
                     :class="{'has-error': $v.address.postalCode.$error}">
                  <label for="address-cep" class="form-label">CEP</label>
                  <div class="input-group">
                    <input type="text" id="address-cep" name="address-cep"
                           class="form-input" v-model="address.postalCode"
                           @blur="$v.address.postalCode.$touch()" placeholder="00.000-000"
                           @change="loadAddress" v-mask-cep autocomplete="nope">
                    <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                            :disabled="loadingAddress" :class="{loading: loadingAddress}">
                      <fa-icon :icon="['fal', 'search']"></fa-icon>
                    </button>
                  </div>
                  <template v-if="$v.address.postalCode.$error">
                    <div class="form-input-hint"
                         v-if="!$v.address.postalCode.required">Campo obrigatório</div>
                    <div class="form-input-hint"
                         v-if="!$v.address.postalCode.cep">CEP inválido</div>
                  </template>
                </div>
                <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
                <div class="column col-9 col-md-12 col-sm-12 form-group">
                  <label for="address-addressLine1" class="form-label">Logradouro</label>
                  <input type="text" id="address-addressLine1" name="address-addressLine1"
                         class="form-input" v-model="address.addressLine1"
                         placeholder="Avenida Brasil, 1000" autocomplete="nope">
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-addressLine2" class="form-label">Complemento</label>
                  <input type="text" id="address-addressLine2" name="address-addressLine2"
                         class="form-input" v-model="address.addressLine2"
                         placeholder="casa, sobrado, loja..." autocomplete="nope">
                </div>
                <div class="column col-5 col-md-6 col-sm-12 form-group">
                  <label for="address-neighborhood" class="form-label">Bairro</label>
                  <input type="text" id="address-neighborhood" name="address-neighborhood"
                         placeholder="Bairro" v-model="address.neighborhood"
                         class="form-input" autocomplete="nope">
                </div>
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="address-city" class="form-label">Cidade</label>
                  <input type="text" id="address-city" name="address-city"
                         class="form-input" v-model="address.city"
                         placeholder="Cidade" autocomplete="nope">
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-state" class="form-label">Estado</label>
                  <select id="address-state" name="address-state"
                          class="form-select" v-model="address.state">
                    <option value="">[Selecione]</option>
                    <option v-for="(text, value) in states"
                            :value="value" :key="value">{{ value }}</option>
                  </select>
                </div>
              </div>

              <div class="text-gray">Outras informações</div>
              <div class="divider"></div>
              <div class="columns">
                <div class="column col-6 col-md-12 col-sm-12 form-group">
                  <label class="form-label">Observações</label>
                  <textarea id="notes" class="form-input notes" rows="4"
                            v-model="form.notes"/>
                </div>
                <div class="column col-6 col-md-12 col-sm-12 form-group">
                  <div class="columns">
                    <div class="column col-12 form-group">
                      <label class="form-label">Alerta</label>
                      <textarea id="notes" class="form-input"
                                v-model="form.alert" />
                    </div>
                    <div class="column col-12 form-group"
                         :class="{'has-error': $v.form.alertAt.$error}">
                      <label class="form-label">Data do alerta (opcional)</label>
                      <dx-input-date
                        class="form-input text-center"
                        v-model="form.alertAt"
                        @blur="$v.form.alertAt.$touch()"
                      />
                      <template v-if="$v.form.alertAt.$error">
                        <div class="form-input-hint"
                             v-if="!$v.form.alertAt.date">Data inválida</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </st-tab>
            <st-tab id="attachments" name="Anexos" v-if="form.id">
              <st-entity-file-manager
                :entity-id="form.id"
                :origin-id="form.id"
                origin-type="patient"
                :list-all="true"
              />
            </st-tab>
            <st-tab id="reports" name="Laudos" v-if="form.id">
              <reports
                :id="form.id"
                :name="form.name"
              />
            </st-tab>
            <st-tab id="appointments" name="Histórico de agendamentos" v-if="form.id">
              <appointments :id="form.id" />
            </st-tab>
          </st-tabs>
        </div>
      </div>
    </template>

    <dx-modal
      v-model="image.showWebCam"
      title="Tirar foto"
      size="sm"
    >
      <image-capture
        v-if="image.showWebCam"
        :width="352"
        :height="264"
        @snapshot="webcamSnapshot"
        ref="webcamSnapshot"
      ></image-capture>

      <div slot="footer">
        <button class="btn btn-gray btn-sm mr-2"
                @click="image.showWebCam = false">Cancelar</button>
        <button class="btn btn-primary btn-sm btn-icon btn-icon-left"
                @click="takeSnapshot" ref="btnTakeSnapshot">
          <fa-icon :icon="['fal', 'camera']"></fa-icon> Tirar foto
        </button>
      </div>
    </dx-modal>

    <modal-alert
      v-if="modalAlertShow && form.id"
      :patient-id="form.id"
      :patient-name="form.name"
      :show="modalAlertShow"
      @close="modalAlertShow = false"
    />
  </st-side-page>
</template>

<script>
import entityMixin from 'src/mixins/entity';
import { required } from 'vuelidate/src/validators';
import {
  CPF,
  PERSON_PRIMARY_TYPES,
  PERSON_SECONDARY_TYPES,
} from 'src/data/identity-types';
import { cep, cpf } from 'src/data/validators';
import { minLength, sameAs } from 'vuelidate/lib/validators';
import { whatsappURL } from '@/helpers/link';
import { hasWebcam } from 'src/helpers/media';
import ImageCapture from 'src/components/image/Capture.vue';
import { statuses as civilStatuses } from 'src/data/civil-statuses';
import Appointments from './Appointments.vue';
import Reports from './Reports.vue';
import ModalAlert from '../modals/Alert.vue';

export default {
  mixins: [entityMixin],
  components: {
    ModalAlert,
    Appointments,
    Reports,
    ImageCapture,
  },
  data() {
    return {
      type: 'patient',
      path: '/patients',
      civilStatuses,
      insurances: [],
      menus: [],
      form: {},
      printing: false,
      changePassword: false,
      profession: null,
      modalAlertShow: false,
      image: this.imageFormBlank(),
      hasWebcam: false,
    };
  },
  computed: {
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    whatsappLink() {
      const number = this.onlyNumbers(this.form.cellphone);
      const name = this.form.name.split(' ')[0];
      return number && number.length >= 10
        ? whatsappURL(number, `Olá ${name}`)
        : null;
    },
    primaryIdentityTypes() {
      return this.form.secondaryIdentity && this.form.secondaryIdentity.type
        ? PERSON_PRIMARY_TYPES.filter(type => type !== this.form.secondaryIdentity.type)
        : PERSON_PRIMARY_TYPES;
    },
    secondaryIdentityTypes() {
      return this.form.identity && this.form.identity.type
        ? PERSON_SECONDARY_TYPES.filter(type => type !== this.form.identity.type)
        : PERSON_SECONDARY_TYPES;
    },
  },
  validations() {
    return this.entityValidationRules((rules) => {
      const localRules = rules;
      localRules.form.identity = {
        value: {},
      };
      localRules.address = {
        postalCode: { cep },
      };
      localRules.form.patient = {
        insurance: {
          planId: { required },
        },
        password: {},
        passwordConfirmation: {},
      };
      localRules.nameAndDob = ['form.name', 'form.birthDate'];

      if (this.form.identity && this.form.identity.type === CPF) {
        localRules.form.identity.value = { cpf };
      }
      if (this.changePassword || this.isNew) {
        localRules.form.patient.password = {
          minLength: minLength(6),
        };

        localRules.form.patient.passwordConfirmation = {
          sameAsPass: sameAs('password'),
        };
      }

      return localRules;
    });
  },
  created() {
    this.form = this.blankForm();
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.form.type = 'person';
    this.loadInsurances();
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load(this.form.id, this.path);
      this.setProfession();

      this.menus = [
        {
          path: `/patients/${this.form.id}/medical-records`,
          label: 'Prontuário',
        },
        {
          path: `/attendances/hospitalizations/${this.form.id}`,
          label: 'Internação',
        },
      ];
    }
    this.hasWebcam = await hasWebcam();
  },
  methods: {
    findCbo(text) {
      const value = text.trim();

      const params = {
        search: value,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    setCbo(data) {
      this.form.profession = {
        code: data.code || '',
        name: data.name,
      };
    },
    unsetCbo() {
      this.form.profession = {
        code: '',
        name: '',
      };
      this.profession = null;
    },
    setProfession() {
      if (this.form.profession && this.form.profession.code) {
        this.profession = this.form.profession;
      }
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    removeImage() {
      this.image = this.imageFormBlank();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    async openWebCam() {
      this.image.showWebCam = true;
      this.image.showOptions = true;
      this.$nextTick(() => this.$refs.btnTakeSnapshot.focus());
    },
    webcamSnapshot(snapshot) {
      if (snapshot !== null) {
        this.image.file = snapshot ? snapshot.file : null;
        this.image.preview = snapshot ? snapshot.dataURL : null;
      }
      this.image.showWebCam = false;
      this.image.showOptions = false;
    },
    async takeSnapshot() {
      await this.$refs.webcamSnapshot.snapshot();
    },
    loadEntityPatient() {
      this.loadEntityByIdentity(this.path)
        .then(() => {
          this.setProfession();
        });
    },
    loadEntityByNameAndDob() {
      if (this.$v.nameAndDob.$invalid) return;
      const params = {
        name: this.form.name,
        birthDate: this.dateToApi(this.form.birthDate),
      };
      this.loadEntity({ params }, this.path)
        .then(() => {
          this.setProfession();
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.apiGetInsurances(params)
        .then((data) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    savePatient() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.form.type = 'person';
      this.address.type = 'residential';

      this.save(this.path)
        .then((data) => {
          if (/create$/.test(this.$route.path)) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }

          if (this.image.file) {
            const formData = new FormData();
            formData.append('file', this.image.file);
            this.$http.post(`/entities/${data.id}/images`, formData)
              .then(() => {
                this.image.file = null;
              })
              .catch(() => {});
          }

          this.$toast.show('Registro salvo');
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.saving = false;
        });
    },
    print() {
      this.printing = true;
      return this.$file
        .print(`/patients/${this.form.id}/print`)
        .catch(() => {})
        .finally(() => {
          this.printing = false;
        });
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .patient-page {
    &.side-page .side-page-side {
      flex: 0 0 10.1rem;
      dd {
        margin-top: 0;
      }
    }
    .patient-image {
      margin-top: $layout-spacing;
      background: $gray-color-light center no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      height: 9rem;
      width: 9rem;
      &:not(.show-options):hover {
        .patient-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .patient-image-options {
          opacity: 1;
        }
      }
    }
    .patient-image-options {
      align-items: center;
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
    .panel {
      background-color: #fff;
    }
    .notes {
      height: 108px;
    }
  }
</style>
