export const statuses = {
  single: 'Solteiro(a)',
  married: 'Casado(a)',
  divorced: 'Divorciado(a)',
  widower: 'Viúvo(a)',
};

export function getName(value) {
  return statuses[value];
}
