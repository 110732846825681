<template>
  <div class="patient-medical-reports">
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <div class="empty mt-2" v-if="data.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Laudos</p>
        <p class="empty-subtitle">
          Nenhum laudo encontrado. Verifique os filtros para realizar novas buscas
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th class="text-center" width="110px">Data do exame</th>
          <th>Exame</th>
          <th>Profissional</th>
          <th>Disponível</th>
          <th class="text-center">Retirada</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in data.items" :key="i">
          <td class="text-center">{{ item.appointment.date | date }}</td>
          <td>
            <span v-if="item.appointment.expenses.length > 0">
              {{ item.appointment.expenses[0].name }}
            </span>
          </td>
          <td>{{ item.steps[item.steps.length -1].professional.name }}</td>
          <td>
            <label class="form-checkbox">
              <input type="checkbox"
                     v-model="item.available"
                     @change="setAvailable(item)">
              <i class="form-icon"/>
            </label>
          </td>
          <td class="text-center">
            <span v-if="item.withdrawalDate">
              {{ item.withdrawalDate | date }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-right" width="70px">
            <button class="btn btn-sm btn-action btn-icon btn-primary tooltip mr-1"
                    data-tooltip="Informar retirada" v-if="!item.withdrawalDate"
                    @click="openWithdrawalModal(item.id)">
              <fa-icon :icon="['fal', 'inbox-out']"></fa-icon>
            </button>
            <button class="btn btn-sm btn-action btn-icon btn-info tooltip mr-1"
                    data-tooltip="Dados da retirada" v-else
                    :disabled="withdrawalDetail.loading"
                    @click="openWithdrawalDetail(item.id)">
              <fa-icon :icon="['fal', 'info-circle']"></fa-icon>
            </button>
            <button class="btn btn-sm btn-action btn-icon btn-gray"
                    :disabled="printing"
                    :class="printing ? {loading: printing && idx === i} : 'tooltip'"
                    @click="print(item.id, i)" data-tooltip="Imprimir">
              <fa-icon :icon="['fal', 'print']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <dx-modal title="Registrar retirada"
              id="modal-withdrawal" size="sm"
              v-if="withdrawalModal.show"
              v-model="withdrawalModal.show">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="withdrawal-date" class="form-label">Data de retirada</label>
          <input type="text" id="withdrawal-date" readonly
                 class="form-input text-center text-bold"
                 v-model="withdrawalModal.date">
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.withdrawalModal.responsibleName.$error}">
          <label for="withdrawal-responsible" class="form-label">
            Nome do responsável
          </label>
          <input type="text" id="withdrawal-responsible" class="form-input"
                 @blur="$v.withdrawalModal.responsibleName.$touch()"
                 v-model="withdrawalModal.responsibleName" v-focus>
          <template v-if="$v.withdrawalModal.responsibleName.$error">
            <div class="form-input-hint"
                 v-if="!$v.withdrawalModal.responsibleName.required">
              Campo obrigatório
            </div>
          </template>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :class="{loading: withdrawalModal.saving}"
                :disabled="withdrawalModal.saving"
                @click="saveWithdrawal">Salvar</button>
        <button class="btn"
                @click="withdrawalModal.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Informações da retirada"
              id="modal-withdrawal" size="sm"
              v-if="withdrawalDetail.show"
              v-model="withdrawalDetail.show">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="withdrawal-date" class="form-label">Data de retirada</label>
          <input type="text" id="withdrawal-detail-date" readonly
                 class="form-input text-center text-bold"
                 v-model="withdrawalDetail.date">
        </div>
        <div class="column col-12 form-group">
          <label for="withdrawal-detail-responsible" class="form-label">
            Nome do responsável
          </label>
          <input type="text" id="withdrawal-detail-responsible" class="form-input"
                 v-model="withdrawalDetail.responsibleName" readonly>
        </div>
        <div class="column col-12 form-group">
          <label for="withdrawal-detail-user" class="form-label">
            Atendido por
          </label>
          <input type="text" id="withdrawal-detail-user" class="form-input"
                 v-model="withdrawalDetail.userName" readonly>
        </div>
      </div>
      <template slot="footer">
        <button class="btn"
                @click="withdrawalDetail.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    id: {
      string: '',
    },
    name: {
      string: '',
    },
  },
  data() {
    return {
      path: '/medical-reports',
      loading: false,
      printing: false,
      idx: null,
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
      withdrawalModal: {
        show: false,
        saving: false,
        id: '',
        date: '',
        responsibleName: '',
      },
      withdrawalDetail: {
        show: false,
        loading: false,
        id: '',
        date: '',
        responsibleName: '',
        userName: '',
      },
    };
  },
  mounted() {
    this.load();
  },
  validations() {
    return {
      withdrawalModal: {
        date: { required },
        saving: false,
        responsibleName: { required },
      },
    };
  },
  methods: {
    setAvailable(item) {
      const data = {
        available: item.available,
      };

      this.$httpX
        .put(`/medical-reports/${item.id}/available`, data)
        .catch(() => {});
    },
    load() {
      this.loading = true;

      const params = {
        limit: 0,
        patientId: this.id,
        period: 'all',
      };

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    openWithdrawalModal(id) {
      this.$v.withdrawalModal.$reset();
      this.withdrawalModal.date = moment().format('DD/MM/YYYY');
      this.withdrawalModal.id = id;
      this.withdrawalModal.responsibleName = this.name;
      this.withdrawalModal.show = true;
    },
    openWithdrawalDetail(id) {
      this.withdrawalDetail.loading = true;

      this.$httpX.get(`/medical-reports/${id}`)
        .then(({ data }) => {
          if (data.withdrawal) {
            this.withdrawalDetail.date = moment(data.withdrawal.date)
              .format('DD/MM/YYYY[ às ]HH:mm');
            this.withdrawalDetail.responsibleName = data.withdrawal.responsibleName;
            this.withdrawalDetail.userName = data.withdrawal.user.name;
            this.withdrawalDetail.show = true;
          }
        })
        .catch(() => {})
        .then(() => {
          this.withdrawalDetail.loading = false;
        });
    },
    saveWithdrawal() {
      this.$v.withdrawalModal.$touch();
      if (this.$v.withdrawalModal.$error) {
        return;
      }

      this.withdrawalModal.saving = true;

      const data = {
        responsibleName: this.withdrawalModal.responsibleName,
      };

      this.$httpX
        .post(`/medical-reports/${this.withdrawalModal.id}/withdrawal`, data)
        .then(({ data: result }) => {
          const found = this.data.items
            .find(({ id }) => id === this.withdrawalModal.id);

          if (found) {
            found.withdrawalDate = result.withdrawal.date;
          }

          this.withdrawalModal.show = false;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Erro ao salvar. Tente novamente!', { type: 'error' });
        })
        .then(() => {
          this.withdrawalModal.saving = false;
        });
    },
    print(id, i) {
      this.printing = true;
      this.idx = i;
      return this.$fileX
        .print(`/medical-reports/${id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .patient-medical-reports {
  }
</style>
