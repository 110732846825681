<template>
  <div class="patient-appointments">
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <div class="empty mt-2" v-if="data.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Histórico de agendamentos</p>
        <p class="empty-subtitle">
          Não foi encontrado nenhum agendamento para este paciente.
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th style="width: 150px">Data</th>
          <th>Agenda</th>
          <th>Convênio</th>
          <th>Status</th>
          <th>Procedimento</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in data.items" :key="i">
          <td>
            <router-link :to="appointmentLink(item)" target="_blank" class="text-primary">
              {{ item.startDate | date('datetime') }}
              <fa-icon :icon="['fal', 'external-link']"></fa-icon>
            </router-link>
          </td>
          <td>
            <span v-if="item.schedule">{{ item.schedule.name }}</span>
            <span v-else class="text-gray">[Sem agenda]</span>
          </td>
          <td>{{ getInsurancePlan(item.insurance) }}</td>
          <td>{{ appointmentStatus(item.status) }}</td>
          <td class="text-ellipsis" style="max-width: 250px">
            <small>{{ getExpenseNames(item.expenses) }}</small>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>

import moment from 'moment';
import { getName as appointmentType } from '@/data/appointment-types';
import { getName as appointmentStatus } from '@/data/appointment-statuses';

export default {
  props: {
    id: {
      string: '',
    },
  },
  data() {
    return {
      loading: false,
      appointmentType,
      appointmentStatus,
      data: {
        items: [],
        total: 0,
        limit: 0,
        offset: 0,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const params = {
        patientId: this.id,
        startDateTime: '2020-01-01',
        limit: this.data.limit,
        offset: this.data.offset,
      };

      this.loading = true;
      this.$http.get('/appointments', { params })
        .then(({ data }) => {
          data.items.sort((a, b) => {
            if (a.startDate > b.startDate) return -1;
            if (a.startDate < b.startDate) return 1;
            return 0;
          });
          this.data.items = data.items;
          this.data.total = data.total;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    getInsurancePlan(insurance) {
      if (insurance.name.toUpperCase() !== insurance.plan.name.toUpperCase()) {
        return `${insurance.name}(${insurance.plan.name})`;
      }
      return insurance.name;
    },
    appointmentLink(appointment) {
      const date = moment(appointment.startDate).format('YYYY-MM-DD');
      const name = encodeURIComponent(appointment.patient.name);
      return `/appointments?date=${date}&s=${name}&scheduleId=ALL`;
    },
    getExpenseNames(expenses) {
      if (!expenses || expenses.length === 0) {
        return '[Procedimento não informado]';
      }
      return expenses
        .map(expense => `${expense.name} ${expense.return ? '[Retorno]' : ''}`).join(', ');
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.patient-appointments {
  .table td {
    font-size: $font-size-sm;
  }
}
</style>
