<template>
  <div class="page-container patients-page">
    <portal to="page-name">Pacientes</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Pacientes</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope" v-focus>
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left mr-2"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Novo paciente
            </button>
            <button class="btn btn-gray btn-icon btn-icon-left"
                    @click="openModalPrint">
              <fa-icon :icon="['fal', 'print']"/>Imprimir
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Pacientes</p>
              <p class="empty-subtitle">
                Nenhum paciente encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th class="hide-sm hide-md">Nascimento</th>
            <th class="hide-sm">CPF</th>
            <th class="hide-sm hide-md">Telefone</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td><router-link :to="editLink(item)">{{ item.name }}</router-link></td>
            <td class="hide-sm hide-md">{{ item.birthDate | date }}</td>
            <td class="hide-sm">
              {{ item.identity ? item.identity.value : '' | cpf }}
            </td>
            <td class="hide-sm hide-md">
              {{ item.cellphone ? item.cellphone : item.phone | phone }}
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
          </template>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>

    <dx-modal title="Relação de pacientes"
              :value="printModal.show" size="sm"
              id="modal-patient-print" @input="printModal.show = false">
      <strong class="text-secondary mb-2">Cadastrados entre</strong>
      <div class="columns">
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.printModal.startDate.$error}">
          <label class="form-label">Data inicial</label>
          <div class="input-group">
            <dx-input-date
              id="start-date"
              class="form-input text-center"
              v-model="printModal.startDate"
              @blur="$v.printModal.startDate.$touch()"
            />
          </div>
          <template v-if="$v.printModal.startDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.printModal.startDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.printModal.startDate.date">Data inválida</div>
          </template>
        </div>
        <div class="column col-6 col-sm-12 form-group"
             :class="{'has-error': $v.printModal.endDate.$error}">
          <label class="form-label">Data final</label>
          <div class="input-group">
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="printModal.endDate"
              @blur="$v.printModal.endDate.$touch()"
            />
          </div>
          <template v-if="$v.printModal.endDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.printModal.endDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.printModal.endDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.printModal.endDate.minDate">Data inválida</div>
          </template>
        </div>
        <div class="column col-12">
          <label class="form-label">Convênio</label>
          <select id="print-insurance" name="print-insurance"
                  class="form-select" v-model="printModal.insurancePlanId">
            <option value="">Todos os convênios</option>
            <option v-for="(item, i) in printModal.insurances"
                    :value="item.plan.id" :key="i">{{ item.customName }}</option>
          </select>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                :class="{loading: printModal.printing}"
                :disabled="printModal.printing"
                @click="print">
          <fa-icon :icon="['fal', 'print']"/>Imprimir
        </button>
        <button class="btn" @click="printModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import { PRINT_PATIENT_LIST } from '@/data/actions/modules/general';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      path: '/patients',
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      printModal: {
        show: false,
        printing: false,
        insurances: [],
        insurancePlanId: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      branchSettings: 'branchSettings',
      isProfessionalOnly: 'isProfessionalOnly',
    }),
    canAccessPrintPatient() {
      if (PRINT_PATIENT_LIST) {
        return this.$can(PRINT_PATIENT_LIST);
      }
      return true;
    },
  },
  validations() {
    const rules = {
      printModal: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.printModal.startDate && moment(this.printModal.startDate).isValid()) {
      rules.printModal.endDate = {
        required,
        date,
        minDate: minDate(this.printModal.startDate),
      };
    }
    return rules;
  },
  mounted() {
    this.load();
  },
  methods: {
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.printModal.insurances = data.items;
        })
        .catch(() => {});
    },
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const listByProfessional = this.branchSettings['patient.groupByProfessional']
        && this.isProfessionalOnly;

      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        notFilterBranch: true,
      };

      if (listByProfessional) {
        params.isProfessionalOnly = true;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      const route = listByProfessional ? this.path : '/patients/search';

      this.$http.get(route, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    openModalPrint() {
      if (!this.canAccessPrintPatient) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      if (this.printModal.insurances.length === 0) {
        this.loadInsurances();
      }
      this.$v.printModal.$reset();
      this.printModal.show = true;
    },
    print() {
      this.$v.printModal.$touch();
      if (this.$v.printModal.$error || this.printModal.printing) {
        return null;
      }

      const duration = moment.duration(moment(this.printModal.endDate)
        .diff(moment(this.printModal.startDate))).asMonths();

      if (duration > 12) {
        this.$toast.show('O intervalo entre as datas não pode ser superior a 12 meses!',
          { type: 'error' });
        return false;
      }

      this.printModal.printing = true;
      const params = {
        startDate: this.printModal.startDate,
        endDate: this.printModal.endDate,
      };

      if (this.printModal.insurancePlanId) {
        params.insurancePlanId = this.printModal.insurancePlanId;
      }

      return this.$file
        .print('/patients/print', params)
        .catch(() => {})
        .then(() => {
          this.printModal.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.patients-page {
  .btn-fixed-width {
    font-size: $font-size-lg;
    height: 2.2rem;
    margin: $layout-spacing-lg 0;
    width: 11rem;
  }
}
</style>
